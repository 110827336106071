import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
import cities from "@/store/helpdesk/cities";
import siteInfo from "@/store/helpdesk/siteinfo";
import cons_feedback from "@/store/consfeedback";

// Load Vuex
Vue.use(Vuex);
// Create store
export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  modules: {
    cities,
    siteInfo,
    cons_feedback,
  },
});
