import axios from "axios";
import { VUE_APP_HELPDESK_API_URL } from "@/config";
import * as events from "@/store/helpdesk/cities/event-types";

export const actions = {
  async HELPDESK_GET_CITIES_FROM_API(
    { commit },
    { sort_name, show_on_sites, status_coven, limit, page }
  ) {
    let filter = {
      sort_name: sort_name,
      show_on_sites: show_on_sites,
      status_coven: status_coven,
      limit: limit,
      page: page,
    };
    for (let key in filter) {
      if (filter[key] === "") {
        delete filter[key];
      }
    }
    return axios
      .get(VUE_APP_HELPDESK_API_URL + "/api/v1/cities", {
        params: filter,
      })
      .then((resp) => {
        commit(events.HELPDESK_SET_CITIES, resp.data);
        return resp.data;
      });
  },

  async HELPDESK_GET_CITY_FROM_API({ commit }, id) {
    return axios
      .get(VUE_APP_HELPDESK_API_URL + "/api/v1/city/" + id)
      .then((resp) => {
        commit(events.HELPDESK_SET_CITY, resp.data);
        return resp.data;
      });
  },

  async HELPDESK_DELETE_CITY({ dispatch }, { id, filters }) {
    return axios
      .delete(VUE_APP_HELPDESK_API_URL + "/api/v1/city/" + id)
      .then(() => {
        dispatch("HELPDESK_GET_CITIES_FROM_API", filters);
      });
  },
};
