<template>
  <div class="section sect_situation" id="sect_situation">
    <HeadBlock></HeadBlock>

    <div class="main_block center-block col-lg-8 col-sm-11 ft_none">
      <div class="row">
        <div class="row col-lg-7">
          <h2>Оцените первичную консультацию</h2>
          <h4>Помогите нам стать лучше. Это займёт не более 1 минуты</h4>
          <!--<p id="prgph1">≈ это займёт не более 1 минуты</p>-->
          <div class="" data-wow-delay="0.6s">
            <div class="form">
              <form action="#" id="vote_form">
                <div id="stars_container">
                  <div class="starrating risingstar d-flex">
                    <input
                      type="radio"
                      id="star10"
                      name="rating"
                      value="10"
                      @click="ratingSelect(10)"
                    /><label for="star10" class="star_one"></label>
                    <input
                      type="radio"
                      id="star9"
                      name="rating"
                      value="9"
                      @click="ratingSelect(9)"
                    /><label for="star9" class="star_one"></label>
                    <input
                      type="radio"
                      id="star8"
                      name="rating"
                      value="8"
                      @click="ratingSelect(8)"
                    /><label for="star8" class="star_one"></label>
                    <input
                      type="radio"
                      id="star7"
                      name="rating"
                      value="7"
                      @click="ratingSelect(7)"
                    /><label for="star7" class="star_one"></label>
                    <input
                      type="radio"
                      id="star6"
                      name="rating"
                      value="6"
                      @click="ratingSelect(6)"
                    /><label for="star6" class="star_one"></label>
                    <input
                      type="radio"
                      id="star5"
                      name="rating"
                      value="5"
                      @click="ratingSelect(5)"
                    /><label for="star5" class="star_one"></label>
                    <input
                      type="radio"
                      id="star4"
                      name="rating"
                      value="4"
                      @click="ratingSelect(4)"
                    /><label for="star4" class="star_one"></label>
                    <input
                      type="radio"
                      id="star3"
                      name="rating"
                      value="3"
                      @click="ratingSelect(3)"
                    /><label for="star3" class="star_one"></label>
                    <input
                      type="radio"
                      id="star2"
                      name="rating"
                      value="2"
                      @click="ratingSelect(2)"
                    /><label for="star2" class="star_one"></label>
                    <input
                      type="radio"
                      id="star1"
                      name="rating"
                      value="1"
                      @click="ratingSelect(1)"
                    /><label for="star1" class="star_one" selected></label>
                  </div>
                </div>

                <br /><br />

                <div class="row" style="margin-bottom: 30px" id="comment_input">
                  <div class="col-md-7 col-sm-10 col-lg-11">
                    <textarea
                      style="width: 90%"
                      id="textarea"
                      rows="4"
                      class="clean"
                      placeholder="Напишите пожалуйста в этом поле, что вам понравилось, а что не понравилось"
                      name="comment"
                      v-model="form.comment"
                    ></textarea>
                  </div>
                </div>

                <div class="row center-block">
                  <div class="col-lg-5 col-md-3 col-sm-5" style="">
                    <div>
                      <input
                        type="submit"
                        class="sub btn_send_review button_link"
                        value="Отправить отзыв"
                        @click.prevent="submit"
                      />
                    </div>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-sm-5 col-xs-12"
                    style="
                      margin-left: 30px;
                      margin-right: 0;
                      padding-left: 0;
                      padding-right: 0;
                    "
                  >
                    <div class="after_submit_btn">
                      <p
                        class="strong_font count_star_text"
                        :hidden="messageAfterClickHiddenStatus"
                      >
                        {{ countStarText }}
                      </p>
                      <p
                        class="message_after_click"
                        :hidden="messageAfterClickHiddenStatus"
                      >
                        {{ messageAfterClick }}
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadBlock from "@/components/HeadBlock";
import autosize from "autosize";
import { mapActions } from "vuex";

export default {
  name: "Form",
  components: {
    HeadBlock,
  },
  props: {
    id: Number,
    consNum: Number,
    consId: Number,
  },
  data() {
    return {
      form: {
        id: 0,
        consNum: 0,
        consId: 0,
        rating: 0,
        comment: "",
      },
      messageAfterClickHiddenStatus: true,
      messageAfterClick: "Мы рады, что вам понравилось общение с нами!",
      countStarText: "Ваша оценка 8 из 10",
    };
  },
  methods: {
    ...mapActions(["CONS_FEEDBACK_VOTE"]),

    async submit() {
      if (this.form.rating === 0) {
        alert("Поставьте оценку");
        return;
      }
      await this.CONS_FEEDBACK_VOTE(this.form);
      this.messageAfterClickHiddenStatus = true;
    },

    ratingSelect(rating) {
      this.form.rating = rating;
      this.countStarText = "Ваша оценка " + rating + " из 10";

      let message = "";
      if (rating === 9 || rating === 10) {
        message = "Мы рады, что вам понравилось общение с нами!";
      }
      if (rating === 8) {
        message = "Рады вам помочь!";
      }
      if (rating === 7) {
        message = "Кажется, мы хорошо поработали.";
      }
      if (rating === 5 || rating === 6) {
        message = "Пожалуйста, напишите, что было не так.";
      }
      if (rating === 3 || rating === 4) {
        message =
          "Могло быть лучше? Пожалуйста, расскажите нам, что не понравилось.";
      }
      if (rating === 1 || rating === 2) {
        message = "Совсем плохо? Пожалуйста, напишите, что вам не понравилось.";
      }

      this.messageAfterClick = message;
      this.messageAfterClickHiddenStatus = false;
    },
  },
  async mounted() {
    autosize(document.getElementById("textarea"));

    if (this.$route.params.id) {
      this.form.id = Number(this.$route.params.id);
    }
    if (this.$route.params.consNum) {
      this.form.consNum = Number(this.$route.params.consNum);
    }
    if (this.$route.params.consId) {
      this.form.consId = Number(this.$route.params.consId);
    }
  },
};
</script>

<style></style>
