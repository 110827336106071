<template>
  <div id="head">
    <div class="col-lg-offset-2 col-sm-offset-0 row">
      <div class="col-lg-4 col-xs-4">
        <router-link
          :to="{ name: 'Home', params: { id: 0, cons: 0 } }"
          class="head_logo"
        >
          <span class="ico"></span>
        </router-link>
      </div>
      <div class="pull-right row col-lg-5 head_contact hidden-xs">
        <div class="ph_bl pull-left">
          <div class="ph"><a href="tel:+78004444217">8 800 444 42 17</a></div>
          <div class="t">Бесплатно по России</div>
        </div>
        <div class="link pull-left col-lg-6">
          <a
            href="popup_call"
            class="popup_open"
            @click.prevent="popupOpenOnClick"
            >Обратный звонок</a
          >
        </div>
      </div>

      <div
        class="
          hidden-sm hidden-lg hidden-md
          pull-right
          row
          col-lg-1
          head_contact
        "
      >
        <div class="link pull-left col-lg-6">
          <a
            href="popup_call"
            class="popup_open"
            @click.prevent="popupOpenOnClick"
            >Обратный звонок</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "HeadBlock",
  methods: {
    popupOpenOnClick() {
      const h = $("#site").height();
      const obj = ".popup_wrapper>.popup_call";
      $(".popup_bg")
        .css({ display: "block", height: h })
        .animate({ opacity: 1 }, 200);
      $(obj).css({ display: "block" });
      const ih = $(window).innerHeight();
      const wh = $(obj).outerHeight();
      let wt = (ih - wh) / 2;
      const st = $(document).scrollTop();
      if (wt < 0) {
        wt = 0;
      }
      wt = wt + st;
      $(obj).css({ top: wt }).animate({ opacity: 1 }, 200);
    },
  },
};
</script>

<style></style>
