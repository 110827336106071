<template>
  <div
    class="section sect_situation2"
    id="sect_situation2"
    style="
      position: relative;
      padding: 0px 0px 60px;
      min-height: 920px;
      margin-top: 0px;
    "
  >
    <HeadBlock></HeadBlock>
    <div style="text-align: center">
      <p style="font-size: xxx-large; font-weight: bold; line-height: 1">
        СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ <br />КЛИЕНТОВ – ФИЗИЧЕСКИХ
        ЛИЦ
      </p>
      <div>«15» июня 2017 г. Омск</div>
    </div>

    <div class="main_block col-lg-offset-1">
      <div class="row" style="text-align: left">
        <p>
          Пользователь, оставляя заявку на сайте prizyvanet.org, принимает
          настоящее Согласие на обработку персональных данных (далее —
          Согласие). Действуя свободно, своей волей и в своем интересе,
          подтверждает, что его возраст соответствует возрастному ограничению
          сайта «18+», а также подтверждает свою дееспособность, пользователь
          дает свое согласие ИП Сухареву Михаилу Александровичу (ИНН
          550617902766, ОГРН 308554333600262, адрес: 644024, г. Омск, ул. Т.К.
          Щербанёва, д.25, офис 204) на обработку своих персональных данных со
          следующими условиями:
        </p>
        <ul class="list-unstyled">
          <li>
            1. Данное Согласие дается на обработку персональных данных, как без
            использования средств автоматизации, так и с их использованием.
          </li>
          <li>
            2. Согласие дается на обработку следующих персональных данных:
          </li>
          <li style="list-style: none; display: inline">
            <ul class="list-unstyled" style="margin-left: 20px">
              <li>
                2.1. Персональные данные, не являющиеся специальными или
                биометрическими:
              </li>
              <li style="list-style: none; display: inline">
                <ul class="list-unstyled" style="margin-left: 20px">
                  <li>2.1.1. Обращение к Пользователю (имя или псевдоним);</li>
                  <li>
                    2.1.2. Номер контактного телефона; пользовательские данные
                    (сведения о местоположении);
                  </li>
                  <li>2.1.3 Тип и версия ОС; тип и версия Браузера;</li>
                  <li>2.1.4. Тип устройства и разрешение его экрана;</li>
                  <li>
                    2.1.5. Параметры источника, откуда пришел пользователь;
                  </li>
                  <li>
                    2.1.6. Реферальные данные сайтов и рекламных площадок;
                  </li>
                  <li>2.1.7. Язык ОС и Браузера;</li>
                  <li>2.1.8. Посещенные страницы;</li>
                  <li>2.1.9. IP-адрес, параметры Cookies.</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>3. Персональные данные не являются общедоступными.</li>
          <li>
            4. Цель обработки персональных данных: обработка входящих запросов
            Физических лиц с целью оказания консультирования; аналитики действий
            Физического лица на веб-сайте и функционирование веб-сайта;
            проведение рекламных и новостных рассылок.
          </li>
          <li>
            5. Основанием для обработки персональных данных является: ст. 24
            Конституции Российской Федераци; ст. 6 Федерального закона №152-ФЗ
            «О персональных данных»; настоящее Согласие на обработку
            персональных данных.
          </li>
          <li>
            6. В ходе обработки с персональными данными будут совершены
            следующие действия:
          </li>
          <li style="list-style: none; display: inline">
            <ul class="list-unstyled" style="margin-left: 20px">
              <li>6.1 Сбор;</li>
              <li>6.2 Запись;</li>
              <li>6.3 Систематизация;</li>
              <li>6.4 Накопление;</li>
              <li>6.5 Хранение;</li>
              <li>6.6 Уточнение;</li>
              <li>6.7 Извлечение;</li>
              <li>6.8 Использование;</li>
              <li>6.9 Удаление;</li>
              <li>6.10 Уничтожение.</li>
            </ul>
          </li>
          <li>
            7. Персональные данные обрабатываются до получения запроса на их
            удаление от субъекта персональных данных. Персональные данные не
            хранятся на бумажных носителях.
          </li>
          <li>
            8. Согласие может быть отозвано субъектом персональных данных или
            его представителем путем направления заявления по электронной почте
            privacy@prizyvanet.ru.
          </li>
          <li>
            9. В случае отзыва субъектом персональных данных или его
            представителем Согласия на обработку персональных данных ИП Сухарев
            М.А. вправе продолжить обработку персональных данных без согласия
            субъекта персональных данных при наличии оснований, указанных в
            пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи
            11 Федерального закона №152-ФЗ «О персональных данных» от 27.07.2006
            г.
          </li>
          <li>
            10. Настоящее согласие действует все время до прекращения обработки
            персональных данных, указанных в п.7 и п.8 данного Согласия.
          </li>
        </ul>
        <br />
        <h2 class="title__head title-star title__head--steps">
          Политика в отношении обработки перс.данных.
        </h2>
        <p>
          Мы заботимся о своих клиентах и не распространяем конфиденциальную
          информацию о Ваших персональных данных в соответствии с нашей
          <a style="text-decoration: underline" href="/private.pdf"
            >Политикой в отношении обработки персональных данных и реализуемых
            требованиях к защите персональных данных</a
          >
          от 13.04.2014. Никто, кроме сотрудников компании, непосредственно
          работающих с Вами, не имеет доступа к Вашим персональным данным.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import HeadBlock from "@/components/HeadBlock";

export default {
  name: "Privacy",
  components: {
    HeadBlock,
  },
};
</script>

<style></style>
