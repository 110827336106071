import Vue from "vue";
import VueRouter from "vue-router";
import Form from "@/components/Form";
import Privacy from "@/components/Privacy";
import { routeNames } from "@/router/const";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:id/:consNum",
    name: routeNames.main,
    component: Form,
  },
  {
    path: "/:id/:consNum/:consId",
    name: routeNames.main,
    component: Form,
  },
  {
    path: "/",
    name: routeNames.feedback,
    component: Form,
  },
  {
    path: "/privacy",
    name: routeNames.privacy,
    component: Privacy,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
