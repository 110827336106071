import axios from "axios";
import { VUE_APP_HELPDESK_API_URL } from "@/config";
import * as events from "@/store/helpdesk/siteinfo/event-types";

export const actions = {
  async HELPDESK_GET_SITE_INFO_FROM_API({ commit }) {
    return axios.get(VUE_APP_HELPDESK_API_URL + "/api/v1/info").then((resp) => {
      commit(events.HELPDESK_SET_SITE_INFO, resp.data);
      return resp.data;
    });
  },
};
