<template>
  <div id="app">
    <div id="site" class="center-block">
      <div id="body">
        <router-view></router-view>
      </div>

      <Footer></Footer>
    </div>

    <Popup></Popup>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Popup from "@/components/Popup";

export default {
  name: "Site",
  components: {
    Footer,
    Popup,
  },
};
</script>

<style></style>
