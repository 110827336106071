import axios from "axios";
import { VUE_APP_CONS_FEEDBACK_URL } from "@/config";

export const actions = {
  async CONS_FEEDBACK_VOTE(state, { id, consNum, consId, rating, comment }) {
    return axios.post(VUE_APP_CONS_FEEDBACK_URL + "/vote", {
        id: id,
        consNum: consNum,
        consId: consId,
        rating: rating,
        comment: comment,
    }).then((resp) => {
      return resp;
    });
  },

  async CONS_FEEDBACK_SEND(
    state,
    {
      domain,
      fp,
      tel,
      name,
      ref_partner,
      email,
      otkuda,
      adv,
      conversion,
      city,
      ip,
    }
  ) {
    const req = JSON.stringify({
      domain: domain,
      fp: fp,
      tel: tel,
      name: name,
      ref_partner: ref_partner,
      email: email,
      otkuda: otkuda,
      adv: adv,
      conversion: conversion,
      city: city,
      ip: ip,
    });
    return axios.post(VUE_APP_CONS_FEEDBACK_URL + "/send", req).then((resp) => {
      return resp;
    });
  },
};
