<template>
  <div>
    <div class="popup_bg" @click="popupCloseOnClick(200)" />

    <div class="popup_wrapper">
      <li class="popup_call">
        <a class="popup_close" @click="popupCloseOnClick(200)"></a>
        <div class="popup_inner">
          <div class="title"><span>Закажи</span> обратный звонок</div>
          <div class="text">
            Ответим на любой вопрос о процессе получения военного билета.
          </div>
          <div class="form">
            <form action="#" id="popup_call_form">
              <div class="dis_none">
                <input
                  type="hidden"
                  class="modal_input"
                  name="formName"
                  value="call"
                />
              </div>
              <div class="p">
                <div
                  class="jq-selectbox jqselect"
                  :class="{
                    opened: selectBoxOpened,
                    focused: selectBoxOpened,
                    dropdown: selectBoxOpened,
                  }"
                  @click="selectBoxOnClick"
                >
                  <div class="jq-selectbox__select">
                    <div class="jq-selectbox__select-text">{{ form.city }}</div>
                    <div class="jq-selectbox__trigger">
                      <div class="jq-selectbox__trigger-arrow"></div>
                    </div>
                  </div>
                  <div
                    class="jq-selectbox__dropdown"
                    style="left: 0; height: auto; bottom: auto; top: 50px"
                    :class="{
                      dis_none: !selectBoxOpened,
                      dis_block: selectBoxOpened,
                    }"
                  >
                    <ul>
                      <li
                        v-for="city in cities"
                        :key="city.id"
                        v-bind:value="city.value"
                        :class="{
                          selected: city.selected,
                          sel: city.sel,
                        }"
                        @click="onSelect(city)"
                      >
                        {{ city.text }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p>
                <label>Как тебя зовут?</label>
                <input
                  type="text"
                  class="modal_input"
                  name="name"
                  v-model="form.name"
                />
              </p>
              <p style="margin-bottom: 10px">
                <label>Номер для связи</label>
                <input
                  id="mobile_input"
                  class="modal_input"
                  type="tel"
                  name="tel"
                  v-model="form.tel"
                />
              </p>
              <input
                type="submit"
                class="sub button_link btn_wait_call"
                value="Жду звонка"
                @click.prevent="submit"
              />
              <div class="agree">
                Нажимая на кнопку, вы соглашаетесь на обработку
                <a href="#" @click="linkOnClick"> персональных данных </a>
              </div>
            </form>
          </div>
        </div>
      </li>

      <li class="popup_thanks">
        <a class="popup_close"></a>
        <div class="popup_inner">
          <div class="title_popup">Спасибо за отзыв!</div>
          <p class="message_after_send"></p>
          <div id="popupstars_container_popup">
            <div
              class="
                popupstarrating
                risingstar
                d-flex
                justify-content-center
                flex-row-reverse
              "
            >
              <template v-for="i of [10, 9, 8, 7, 6, 5, 4, 3, 2, 1]">
                <input
                  :key="'input_' + i"
                  type="radio"
                  :id="'superstar' + i.toString()"
                /><label
                  :key="'label_' + i"
                  :for="'superstar' + i.toString()"
                ></label>
              </template>
            </div>
          </div>
        </div>
      </li>

      <li class="popup_phone">
        <a class="popup_close"></a>
        <div class="popup_inner">
          <div class="title">8 800 555-85-47</div>
          <div class="text">Бесплатно по России</div>
        </div>
      </li>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import $ from "jquery";
import Inputmask from "inputmask";
import { routeNames } from "@/router/const";

export default {
  name: "Popup",
  computed: {
    ...mapGetters(["helpdeskCities"]),
  },
  data() {
    return {
      form: {
        domain: "",
        fp: "",
        tel: "",
        name: "",
        ref_partner: "",
        email: "",
        otkuda: "",
        adv: "",
        conversion: "",
        city: "Выбери город",
        ip: "",
      },
      filters: {
        sort_name: null,
        show_on_sites: null,
        status_coven: null,
        limit: 1000,
        page: 1,
      },
      cities: [],
      selectBoxOpened: false,
    };
  },
  methods: {
    ...mapActions(["HELPDESK_GET_CITIES_FROM_API", "CONS_FEEDBACK_SEND"]),

    selectBoxOnClick() {
      this.selectBoxOpened = !this.selectBoxOpened;
    },

    onSelect(city) {
      let id = this.cities.findIndex((x) => x.selected === true);
      this.cities[id].selected = false;

      id = this.cities.findIndex((x) => x.id === city.id);

      this.cities[id].selected = true;
      this.form.city = city.value;
    },

    submit() {
      this.CONS_FEEDBACK_SEND(this.form);
    },

    linkOnClick() {
      this.popupCloseOnClick(0);
      this.$router.push({ name: routeNames.privacy });
    },

    popupCloseOnClick(wait) {
      $(".popup_wrapper>.popup_call").animate({ opacity: 0 }, wait).hide(0);
      $(".popup_wrapper>li").animate({ opacity: 0 }, wait).hide(0);
      $(".popup_bg").animate({ opacity: 0 }, wait).hide(0);
    },
  },
  async mounted() {
    this.filters.show_on_sites = true;
    await this.HELPDESK_GET_CITIES_FROM_API(this.filters).catch(() => {
      alert("Произошла ошибка при получении списка городов");
    });

    let id = 0;
    this.cities.push({
      value: "",
      sel: true,
      selected: true,
      id: id,
      text: "Выбери город",
    });

    let helpdeskCities = JSON.parse(JSON.stringify(this.helpdeskCities));

    let m = helpdeskCities.findIndex((v) => v.id === 1); // Москва
    let ma = helpdeskCities.findIndex((v) => v.id === 57); // Москва-Арбат
    let p = helpdeskCities.findIndex((v) => v.id === 2); // Санкт-Петербург

    console.log(this.helpdeskCities[p]);

    let arr = [m, ma, p];
    for (let i in arr) {
      id++;
      this.cities.push({
        value: helpdeskCities[arr[i]].name,
        sel: false,
        selected: false,
        id: id,
        text: helpdeskCities[arr[i]].name,
      });
    }
    helpdeskCities.splice(m, 1);
    ma = helpdeskCities.findIndex((v) => v.id === 57); // Москва-Арбат
    helpdeskCities.splice(ma, 1);
    p = helpdeskCities.findIndex((v) => v.id === 2); // Санкт-Петербург
    helpdeskCities.splice(p, 1);

    console.log(this.cities);

    let mb = helpdeskCities.findIndex((v) => v.id === 44); // Москва-Балашиха
    helpdeskCities[mb].name = "Балашиха";

    let mm = helpdeskCities.findIndex((v) => v.id === 45); // Москва-Мытищи
    helpdeskCities[mm].name = "Мытищи";

    let d = helpdeskCities.findIndex((v) => v.id === 50); // Другой
    helpdeskCities.splice(d, 1);

    let citiesSorted = [];
    for (let city of helpdeskCities) {
      id++;
      citiesSorted.push({
        value: city.name,
        sel: false,
        selected: false,
        id: id,
        text: city.name,
      });
    }
    citiesSorted.sort(function (a, b) {
      if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
      if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
      return 0;
    });

    for (let city of citiesSorted) {
      id++;
      this.cities.push({
        value: city.text,
        sel: false,
        selected: false,
        id: id,
        text: city.text,
      });
    }

    id++;
    this.cities.push({
      value: "Другой",
      sel: false,
      selected: false,
      id: id,
      text: "Другой",
    });

    this.form.otkuda = document.referrer;
    this.form.adv = location.pathname + location.search;
    this.form.ref_partner = Math.floor(Date.now() / 1000) * 2517;

    let input = $(
      "input[type='text'],input[type='email'],input[type='tel'],input[type='password'], textarea"
    );
    input.focusin(function () {
      $(this).addClass("clean");
    });
    input.focusout(function () {
      $(this).removeClass("clean");
    });

    let im = Inputmask("+7 (###) ###-##-##");
    im.mask(document.getElementById("mobile_input"));
  },
};
</script>

<style></style>
