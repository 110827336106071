import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtm from "@gtm-support/vue2-gtm";
import VueGtag from "vue-gtag";

import "@/assets/css/slick.css";
import "@/assets/css/jquery.formstyler.full.css";
import "@/assets/css/jquery.fancybox.min.css";
import "@/assets/css/animate.3.5.2.css";
import "@/assets/css/bootstrap.min.css";
import "@/assets/css/style.css";
import "@/assets/css/custom.css";

Vue.use(VueGtag, {
  config: { id: "UA-73216612-8" },
});

Vue.config.productionTip = false;

Vue.use(VueGtm, {
  id: "GTM-5TVQ3Z6",
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
