<template>
  <div id="footer" class="navbar-fixed-bottom">
    <div class="row col-lg-12">
      <div class="col-lg-offset-2 col-lg-4">
        <p class="footer_title">ООО "МЕДИЦИНСКО - ПРАВОВАЯ КОМПАНИЯ"</p>

        <p class="footer_subtitle footer_licence">
          {{ helpdeskSiteInfo.license }}
        </p>
      </div>

      <div class="col-lg-4 col-xs-12 pull-right">
        <p class="footer_subtitle">
          Администратор доменного имени:<br />ИП Сухарев Михаил Александрович
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Footer",
  computed: {
    ...mapGetters(["helpdeskSiteInfo"]),
  },
  methods: {
    ...mapActions(["HELPDESK_GET_SITE_INFO_FROM_API"]),
  },
  async mounted() {
    await this.HELPDESK_GET_SITE_INFO_FROM_API();
  },
};
</script>

<style></style>
